<template>

  <div class="auth flex flex-col md:flex-row p-4  items-center ">
   <!--  <div class="flex-1 flex justify-end  md:block">
      <img
        alt="Música Protegida"
        src="@/assets/logo.svg"
        class="ml-4 logo"
        style="max-width: 420px"
      />
    </div> -->
    <div class="right_part">
      <h1 class="color_white" style="font-size: 40px;">{{ $t("login.bemVindo") }}</h1>
      <p class="color_white mb-4">
        {{ $t("login.nao-tem-conta") }}
        <router-link class="text-primary" to="/sign-up">
          {{ $t('login.cadastrar') }}
        </router-link>
      </p>
      <div class="box edit_box w-full">
        <div class="mb-4">
          <span class="nameInput block mb-2"> {{ $t("login.email") }} </span>
          <div class="confirm">
            <input
              type="text"
              @keyup.enter="submit"
              class="form-control"
              placeholder=""
              v-model="form.username"
            />
          </div>
        </div>
        <div class="mb-4">
          <span class="nameInput block mb-2">{{ $t("login.password") }}</span>
          <div class="confirm">
            <input
              type="password"
              @keyup.enter="submit"
              class="form-control"
              placeholder="Enter your password"
              v-model="form.password"
            />
          </div>
          <p class="text-red text-sm mt-3" v-if="error">{{ error }}</p>
        </div>
        <div class="flex justify-between flex-wrap">
          <a
            href="javascript:void(0);"
            @click="submit"
            class="w-full mb-2 btn btn-grad btn-lg "
            :disabled="loading"
          >
            <img
              v-if="loading"
              width="25"
              class="block mx-auto"
              :src="require('@/assets/spinner.svg')"
              alt=""
            />
            <span v-else>{{ $t("login.login") }}</span>
          </a>
          <router-link
            to="/sign-up"
            style="box-shadow: none !important;"
            class="btn btn-sm text-primary"
            >{{ $t("login.cadastrar") }}</router-link
          >
          <router-link
            to="/forgot-password"
            style="box-shadow: none !important;"
            class="btn btn-sm text-primary"
            >{{ $t("login.esqueci-senha") }}</router-link
          >
        </div>
      </div>
    </div>
  </div>
  
</template>

<script>
import { mapActions } from "vuex";
import { oauth, default as api } from "@/api";

export default {
  data() {
    return {
      form: { username: "", password: "" },
      error: false,
      loading: false,
    };
  },
  methods: {
    ...mapActions(["setToken", "setUser", "setWallet"]),
    async submit() {
      this.loading = true;
      const response = await oauth.post("token", this.form);

      if (
        response.token_type &&
        response.access_token &&
        response.token_type == "Bearer"
      ) {
        this.setToken(response.access_token);
        this.user = await api.get("user");
        // if (this.user.two_factor_enable == 0) {
        this.setUser(this.user.data);
        this.setWallet(false);

        const params = new Proxy(new URLSearchParams(window.location.search), {
          get: (searchParams, prop) => searchParams.get(prop),
        });

        if (params.redirect) {
          this.$router.push(params.redirect);
        } else {
          this.$router.push({ name: "Home" });
        }

        // } else {
        //   this.show = 'authentication'
        // }
      } else {
        this.error = "Usuário ou senha inválidos";
      }

      this.loading = false;
    },
  },
};
</script>

<style>
.auth {
  overflow-x: hidden !important;

  background-image: url("../../assets/11.jpg");
  min-height: calc(100vh - 75px);
  background-position: -1px -1px;
}

.right_part {
  padding: 20px 0;
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media screen and (min-width: 1024px) {
  .right_part {
    padding: 60px;
  }
}

@media screen and (max-width: 768px) {
  .right_part {
    width: 100%;
  }
}

/**
css animations
*/

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(-25px);
  opacity: 0;
}
</style>
